import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertsContext, AlertType, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";

interface IResponse {
    readonly jobSpec: string;
}

const getMutationQuery = (token: string | null, jobId: string) => {
    const postRequest = async (): Promise<IResponse> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.post(`${Constants.ApiRoot}/admin/job/${jobId}/get-params`, {}, config);

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const usePostJobGetConfig = (jobId: string, onSuccess: (response: IResponse) => void) => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);

    const mutation = useMutation(getMutationQuery(authToken, jobId), {
        onMutate: () => {},
        onSuccess: (data) => {
            onSuccess(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
        },
    });
    return { mutation };
};
