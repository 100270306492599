import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

interface IResponse {
    readonly users: Array<{
        readonly modified: string;
        readonly _id: string;
        readonly email: string;
    }>;
}

const getUsers = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/users`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useUsers = () => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Users], () => getUsers(config));
};
